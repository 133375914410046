import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SocketioService } from './services/socket/socketio.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavbarauthComponent  } from './components/navbarauth/navbarauth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/auth/login-form/login.component';
import { AuthService } from './services/auth/auth.service';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { LogoutComponent } from './components/auth/logout/logout.component';
import {  NoAuthGuard } from './guards/NoAuth.guard';
import { JwtModule } from "@auth0/angular-jwt";
import { RegistroComponent } from './components/auth/registro/registro.component';
import { UserService } from './services/user/user.service';
import { LaService } from './modules/la/servises/la.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { IdentGuard } from './guards/ident.guard';
import { IsAuthGuard } from './guards/isAuth.guard';
import { ChatComponent } from './components/chat/chat.component';
import { SearchClientComponent } from './components/search-client/search-client.component';
import { ConsultaPsicometricoComponent } from './components/consulta-psicometrico/consulta-psicometrico.component';
import { ListOnlineComponent } from './components/list-online/list-online.component';
import { ProfilesComponent } from './components/profiles/profiles.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactProfileComponent } from './components/contact-profile/contact-profile.component';
import { CommonModule } from '@angular/common';
import { ConsultQuoteComponent } from './components/consult-quote/consult-quote.component';
import { CreditOptionComponent } from './components/credit-option/credit-option.component';
import { ConsultQuotaComponent } from './components/consult-quota/consult-quota.component';
import { CreditCalculatorComponent } from './components/credit-calculator/credit-calculator.component';
import { CreditSaveComponent } from './components/credit-save/credit-save.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ValidatePhoneComponent } from './components/validate-phone/validate-phone.component';
import { ApproveConditionsComponent } from './components/approve-conditions/approve-conditions.component';
import { ContactOnboardingComponent } from './components/contact-onboarding/contact-onboarding.component';
import { ContactFinancialPaymentsComponent } from './components/contact-financial-payments/contact-financial-payments.component';
import { PostComponent } from './components/post/post.component';
import { PersonPaymentsComponent } from './components/person-payments/person-payments.component';
import { WebcamComponent } from './components/webcam/webcam.component';
import { WebcamModule } from 'ngx-webcam';
import { NgToggleModule } from 'ng-toggle-button';

import { BiometriaComponent } from './components/biometria/biometria.component';
import { FinancialPaymentsComponent } from './components/financial-payments/financial-payments.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { StadisticComponent } from './components/stadistic/stadistic.component';
import { InstallmentsConsoleComponent } from './components/installments-console/installments-console.component';
import { DataProfileComponent } from './components/data-profile/data-profile.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddressComponent } from './components/address/address.component';
import { WorkingConditionComponent } from './components/working-condition/working-condition.component';
import { IncomeComponent } from './components/income/income.component';
import { ReportComponent } from './components/report/report.component';
import { ContactsApprovatedComponent } from './components/contacts-approvated/contacts-approvated.component';
import { ExportComponent } from './components/export/export.component';
import { CreateContactComponent } from './components/create-contact/create-contact.component';
import { DashboardInviceComponent } from './components/dashboard-invice/dashboard-invice.component';
import { UsersComponent } from './components/users/users.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { FinancalDashboardComponent } from './components/financal-dashboard/financal-dashboard.component';
import { StadisticDashboardComponent } from './components/stadistic-dashboard/stadistic-dashboard.component';
import { StadisticEjecutivesComponent } from './components/stadistic-ejecutives/stadistic-ejecutives.component';
import { IsProfileGuard } from './guards/isProfile.guard';
import { DashboardDirecDebitComponent } from './components/dashboard-direc-debit/dashboard-direc-debit.component';
import { DirectDebitService } from './services/direct-debit/direct-debit.service';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { DndComponent } from './components/dashboard-direc-debit/dnd/dnd.component';
import { DndAlliancesComponent } from './components/dashboard-direc-debit-alliances/dnd/dnd.component';
import { DndComponent as DndAfiliationComponent } from './components/dashboard-direct-debit-affiliation/dnd/dnd.component';

import { ProgressDndComponent } from './components/dashboard-direc-debit/progress-dnd/progress-dnd.component';
import { DndDirective } from './directives/dnd.directive';
import { DirecDebitHistoryComponent } from './components/direc-debit-history/direc-debit-history.component';

import { CurrencyMaskModule } from "ng2-currency-mask";
import { EditComponent } from './components/payment-method/edit/edit.component';
import { NotificationXlsxComponent } from './components/notification-xlsx/notification-xlsx.component';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { DashboardDirecDebitAlliancesComponent } from './components/dashboard-direc-debit-alliances/dashboard-direc-debit-alliances.component';

import { RatesComponent } from './components/rates/rates.component';
import { PlansComponent } from './components/plans/plans.component';
import { NewPlanComponent } from './components/new-plan/new-plan.component';
import { CargaMasivaClientComponent } from './components/carga-masiva-client/carga-masiva-client.component';
import { DirecDebitAlliancesHistoryComponent } from './components/direc-debit-alliances-history/direc-debit-alliances-history.component';

import { TraceDocumentComponent } from './components/dashboard-direc-debit/trace-document/trace-document.component';
import { EditDataBaseComponent } from './components/edit-data-base/edit-data-base.component';
import { DashboardDirectDebitAffiliationComponent } from './components/dashboard-direct-debit-affiliation/dashboard-direct-debit-affiliation.component';
import { DirecDebitAfiliationsHistoryComponent } from './components/direc-debit-afiliations-history/direc-debit-afiliations-history.component';
import { ClientPlansComponent } from './components/person-plans/person-plans.component';
import { AccountsAdminComponent } from './components/accounts-admin/accounts-admin.component';
import { CargaMasivaAprobacionesComponent } from './components/carga-masiva-aprobaciones/carga-masiva-aprobaciones.component';
import { CargaMasivaCuposComponent } from './components/carga-masiva-cupos/carga-masiva-cupos.component';
// import { ClientsStadisticAuthComponent } from './components/clients-stadistic-auth/clients-stadistic-auth.component';
import { ClientsStadisticAuthComponent } from './components/clients-stadistic-auth/clients-stadistic-auth.component';
import { OrdersExportsComponent } from './components/orders-exports/orders-exports.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ModalComponent } from './notifications/edit/edit.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ClientReconsiderationComponent } from './components/client-reconsideration/client-reconsideration.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarauthComponent,
    LoginComponent,
    LogoutComponent,
    RegistroComponent,
    DashboardComponent,
    ChatComponent,
    SearchClientComponent,
    ConsultaPsicometricoComponent,
    ListOnlineComponent,
    ProfilesComponent,
    ContactsComponent,
    ContactProfileComponent,
    ConsultQuoteComponent,
    CreditOptionComponent,
    ConsultQuotaComponent,
    CreditCalculatorComponent,
    CreditSaveComponent,
    InvoiceComponent,
    ValidatePhoneComponent,
    ApproveConditionsComponent,
    ContactOnboardingComponent,
    ContactFinancialPaymentsComponent,
    PostComponent,
    PersonPaymentsComponent,
    WebcamComponent,
    BiometriaComponent,
    FinancialPaymentsComponent,
    PaymentsComponent,
    StadisticComponent,
    InstallmentsConsoleComponent,
    DataProfileComponent,
    AddressComponent,
    WorkingConditionComponent,
    IncomeComponent,
    ReportComponent,
    ContactsApprovatedComponent,
    ExportComponent,
    CreateContactComponent,
    DashboardInviceComponent,
    UsersComponent,
    LineChartComponent,
    FinancalDashboardComponent,
    StadisticDashboardComponent,
    StadisticEjecutivesComponent,
    DashboardDirecDebitComponent,
    PaymentMethodComponent,
    DndComponent,
    DndAlliancesComponent,
    DndAfiliationComponent,
    ProgressDndComponent,
    DndDirective,
    DirecDebitHistoryComponent,
    EditComponent,
    NotificationXlsxComponent,
    NumbersOnlyDirective,
    DashboardDirecDebitAlliancesComponent,
    RatesComponent,
    PlansComponent,
    NewPlanComponent,
    CargaMasivaClientComponent,
    NumbersOnlyDirective,
    DirecDebitAlliancesHistoryComponent,
    TraceDocumentComponent,
    EditDataBaseComponent,
    DashboardDirectDebitAffiliationComponent,
    DirecDebitAfiliationsHistoryComponent,
    ClientPlansComponent,
    AccountsAdminComponent,
    CargaMasivaAprobacionesComponent,
    CargaMasivaCuposComponent,
    //ClientsStadisticAuthComponent
    ClientsStadisticAuthComponent,
    OrdersExportsComponent,
    NotificationsComponent,
    ModalComponent,
    ClientReconsiderationComponent
  ],

  imports: [ 
    FormsModule,
    ReactiveFormsModule,
    
    MbscModule,  
    JwtModule.forRoot({
      config: {
       // allowedDomains: ['example.com'],
        disallowedRoutes: ['/assets/config.json'],
      },
    }),
 
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy:'registerImmediately' 
    }),
    NgbModule,
    CommonModule,
    WebcamModule,
    InfiniteScrollModule,
    NgToggleModule,
    CurrencyMaskModule,
    AngularEditorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    SocketioService,
    AuthService,
    UserService,
    LaService,
    IdentGuard,
    IsAuthGuard,
    NoAuthGuard,
    IsProfileGuard,
    DirectDebitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
