<!-- <pre> {{ sections_status | json }}</pre> -->




<div class="row mb-5">
    <div class="col-12 ">

        <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
                <h5 class="card-title">Datos basicos
                    <span  class="float-end fas fa-check text-success ps-1 small"></span>

                </h5>

                <p class="card-text"> <span class="text-capitalize fw-bold">{{person?.person?.name}} {{person?.person?.lastName}}</span> <br> {{person?.person?.gender == 'M'?'Masculino':'Femenino'}} , {{getIdentity(person)}} <br> Fecha de nacimiento {{person?.person?.birth_date | date: 'dd/MM/yyyy'}}, <br>
                    {{ person.person.marital_status.name }}
                </p>
                <!-- <div class="w-100 text-end mt-4">
                    <a href="#" class="btn btn-primary ">Editar</a>
                </div> -->

                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(7)">Editar</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12  mt-4">

        <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">

                <h5 class="card-title">Contactos
                    <span *ngIf="sections_status['CONTACT_ONB_APK'] ==  'COMPLETE'" class="float-end fas fa-check text-success ps-1 small"></span>

                </h5>
                <p class="card-text">


                    Teléfono Principal   <span class="fw-bold" >  {{getContact(person)}}   </span>

                    <span class="fw-bold" *ngIf="person?.constacts[0]?.have_whatsapp"> <i class="text-success fab fa-whatsapp"></i> </span><br>
                   <ng-container *ngIf="getContact2(person)!=='undefined'">Otro Teléfono   <span class="fw-bold"  >  {{getContact2(person)}}   </span> <br>  </ng-container>  <br>

                   Email <span class="fw-bold" > {{getEmailContact(person)}} </span>
                </p>
                <!-- <div class="w-100 text-end mt-4">
                    <a href="#" class="btn btn-primary ">Editar</a>
                </div> -->

                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(7)">Editar</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Medios de pago
                    <span *ngIf="sections_status['PAYMENT_METHODS_ONB_APK'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <div class="card-text small">
                    <div *ngIf="!loadPerson_Address">

                        <div class="mt-2" *ngFor="let item of accounts; let i=index">
                            <div *ngIf="i > 1">
                                <hr>
                            </div>

                            <p class="card-text ">
                                <span class="card-title fw-bold ">{{item?.bank?.name}}</span>, {{ item?.account_type==="00"?"Corriente": "Ahorro" }}  ({{item?.currency?.name}}), {{item?.account_number}},

                                <!-- <ng-container *ngIf="!item?.direct_debit_default"> -->
                                    <span class="text-success">{{item?.direct_debit_default?'Domiciliada':''}}</span>
                                <!-- </ng-container>
                                <ng-container *ngIf="item?.bank?.direct_debit_afiliation_required">
                                    <span class="text-success">{{item?.terms_and_condition && item?.afiliation?.status === "COMPLETE" ?'Domiciliada': (item?.afiliation?.status === "PENDING"?'Afiliación Pendiente':'')}}</span>
                                </ng-container> -->


                            </p>
                        </div>


                    </div>


                    <ng-container *ngIf="loadPerson_Address">
                        <div class="w-100 text-center mt-2">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                </div>
                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(5)">Editar</a>
                </div>
            </div>

        </div>
    </div>
    <div class="col-12 mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Direcciones
                    <span *ngIf="sections_status['HOME_ADDRESS_ONB_APK'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <div class="card-text small">

                    <div *ngIf="!loadPerson_Address">
                        <span *ngIf="sections_status['HOME_ADDRESS_ONB_APK'] ==  'COMPLETE' && personAddress?.length < 1" class="float-end  fas fa-check text-success ps-1 small"></span>

                        <div class="mt-2" *ngFor="let item of personAddress; let i=index">
                            <div *ngIf="i > 1">
                                <hr>
                            </div>
                            <b>{{item?.address_type?.name}}</b>,{{item?.address?.country?.description}}, {{item?.address?.state?.name}}, {{item?.address?.city?.name}}, {{item?.address?.description}}
                            <span  class="fw-bold" *ngIf="item?.housing_condition">                            ({{item?.housing_condition}})
                            </span>
                        </div>


                    </div>


                    <ng-container *ngIf="loadPerson_Address">
                        <div class="w-100 text-center mt-2">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                </div>
                <!-- <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(2)">Editar</a>
                </div> -->
            </div>
        </div>
    </div>
   <!--<pre>{{ customer | json }}</pre> -->
    <div class="col-12 mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Condición Laboral
                    <span *ngIf="sections_status['WORKING_INF_ONB_APK'] ==  'COMPLETE'" class=" float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <p *ngIf="sections_status['WORKING_INF_ONB_APK'] !=  'COMPLETE'" class="text-secondary small"> Pendiente</p>



                <p class="card-text">Condición Laboral <span *ngIf="customer?.working_condition != 'Otro'" class="fw-bold">{{customer?.working_condition}}</span></p>
                <p class="card-text">Descripción <span class="fw-bold">{{customer?.working_condition_descripion}}</span> </p>

                <p class="card-text" *ngIf="customer?.job_type" >Tipo de empleo <span class="fw-bold"  > {{customer?.job_type}} </span> </p>
                <p class="card-text" *ngIf="customer?.company_name" >Nombre de la empresa donde labora <span class="fw-bold" > {{customer?.company_name}} </span> </p>
                <p class="card-text" *ngIf="customer?.position">Cargo en la empresa donde labora <span class="fw-bold" > {{customer?.position}} </span> </p>
                <p class="card-text" *ngIf="customer?.admission_date">Fecha de ingreso a la empresa <span class="fw-bold" > {{customer?.admission_date | date: 'dd/MM/yyyy' }} </span> </p>
                <p class="card-text"  *ngIf="customer?.egress_date">Fecha de egreso de la empresa para la condición laboral Jubiliado y pensionado<span class="fw-bold"> {{customer?.egress_date | date: 'dd/MM/yyyy'}} </span> </p>

               <!--   <div *ngIf="!estatus_approve"  class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(3)">Editar</a>
                </div>-->
            </div>
        </div>
    </div>
    <div class="col-12 mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Finanzas
                    <span *ngIf="sections_status['FINANCE_ONB_APK'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>
                </h5>

                <p class="card-text">Ingresos Mensuales <span class="fw-bold" >{{customer?.amount}} USD</span> </p>

                <p class="card-text" *ngIf="customer?.other_income" >Otros ingresos<span class="fw-bold"  > {{customer?.other_income}} USD</span> </p>
                <p class="card-text" *ngIf="customer?.monthly_expenses" >Gastos mensuales<span class="fw-bold"  > {{customer?.monthly_expenses}} USD</span> </p>
                <p class="card-text" *ngIf="customer?.other_expenses" >Otros gastos<span class="fw-bold"  > {{customer?.other_expenses}} </span> USD</p>
                <p class="card-text" *ngIf="customer?.family_burden" >Carga familiar<span class="fw-bold"  > {{customer?.family_burden}} </span> </p>



               <!--   <div *ngIf="!estatus_approve" class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(4)">Editar</a>
                </div>-->
            </div>
        </div>
    </div>
</div>
