export const environment = {
  production: false,
  inactive: false,

  BASE_LA: 'https://staging-api.creditotal.online',
  BASE_FORNT2: 'https://staging-console.creditotal.online',
  BASE_FORNT: 'https://staging-app.creditotal.online',
  redirectRoute:"/redirect",
  searchClient:"/console/create-constacts",
  psicometrico:"/console/psicometrico",
  initRoute:"/console/search-client",

  externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',

  loginRoute:"/login",
  logoutRoute:"/logout",

  socketBase:{
    SOCKET_ENDPOINT: 'https://staging-socket-api.creditotal.online/'
  },
  

  ClieID:"12",
  CED:"11314836",
  IP_LOCAL:"159.223.176.142",


  ONBOARDING_LA : 'https://onboarding-api.akeela.co',

  TRUSTX : 'https://trustx-dev-api.akeela.co'

};
