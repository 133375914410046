<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Contacto
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-8 offset-md-2">

                    <app-search-client (close)="closeAddClient()"></app-search-client>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-8 offset-md-2  p-0 ">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="  fas fa-users"></i> Contactos
                    <!-- <div class="mt-1 float-md-end">
                        <button type="button " class="btn btn-outline-primary primary  " (click)="openBottom(content,{})">Crear Cliente</button>
                    </div> -->
                </h5>

                <div class="row"> 
                    <div class=" mt-2 col-12 col-md-6 ">
                        <div class="form-floating ">

                            <select (change)="getPeople(true)" [(ngModel)]="approveStatus" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                          <option   value="ALL">Todos</option>
                          <option   value="REFUSED">Rechazado</option>
                          <option   value="APPROVED">Aprobado</option>
                        </select>
                            <label for="floatingSelect ">Estatus</label>
                        </div>

                    </div>

                    


                    <div class=" mt-2 pt-2 col-12 col-md-6 ">
                        En revision 
                        <ng-toggle (change)="toogleStatusClient()" [(ngModel)]="usQuoteClien" [value]="true" [color]="{
                            checked: '#01abce',
                            unchecked: '#dcdcdc'
                        }"></ng-toggle>
    
                    </div>
               
                    <div class="mt-2 col-12 col-md-6 ">
                        <div class="form-floating ">

                            <select (change)="getPeople(true)" [(ngModel)]="biometricStatus" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                          <option   value="ALL">Todos</option>
                          <option   value="Accepted">Aprobado</option>
                          <option   value="Pending">Pendiente</option>
                          <option   value="Declined">Rechazado</option>
                        </select>
                            <label for="floatingSelect ">Biometria</label>
                        </div>

                    </div>

                    <div class="mt-2 col-12 col-md-6 ">
                        <div class="form-floating ">

                            <select (change)="getPeople(true)" [(ngModel)]="onboardingStatus" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                          <option   value="ALL">Todos</option>
                          <option   value="COMPLETE">Completado</option>
                          <option   value="IN_PROGRES">Pendiente</option>
                        </select>
                            <label for="floatingSelect ">Onbordig</label>
                        </div>

                    </div>
                </div>


                    
    


               <!-- <div class="form-group col-12 mb-3 ">
                    <br>



                    <div class="input-group ">

                        <div class="form-floating ">
                            <input type="text" [(ngModel)]="search_form" class="form-control " id="contact" placeholder="name@example.com">
                            <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Nombe, Apellido
                            </span>
                        </label>
                        </div>
                        <button (click)="getPeople(true)" [disabled]=" submit_disabled" class="btn btn-primary">
                        Buscar
                        <span *ngIf="submit_disabled">
                        <i class="fa  fa-spinner fa-spin"></i>
                        </span> 
                        </button>
                    </div>

                </div> -->


                <div class="mt-5" *ngIf="people ">


                    <div class="float-end text-primary" *ngIf="!reloadStadisticLoading">
                        <i  class="fas fa-sync-alt"></i>
                    </div>
                   <!--  <div class=" small" *ngIf="!reloadStadisticLoading"> Ult. actualización {{client_stadistic?.date  | date: 'dd/MM/yyyy h:mm:ss a'}} 
    -->

                     <div class=" small" *ngIf="!reloadStadisticLoading"> Ult. actualización 

                    </div>
    
                    <div class=" small " *ngIf="reloadStadisticLoading"> actualizando... 
    
                    </div>
    
                <div *ngIf="people " class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">


                    <h6 class="border-bottom pb-2 mb-0">Nombre
                        <div class="float-end text-secondary"> {{rows.length}} / {{people_total}}</div>
                    </h6>

                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">
                        <ng-container>
                                                        <!--[routerLink]="['/console/person/profile/',item?.code]"-->




                            <div  *ngFor="let item of rows ; let i=index" class="d-flex text-muted pt-4">


                                <div *ngIf="item?.params?.selfie">

                                    <div [ngClass]="{'active': usersActives[item?.params?.code]}" style=" background-image: url({{item?.params?.selfie}}); " class=" flex-shrink-0 me-2 base-img ">
                                    </div>
                                    <!-- <img [ngClass]="{'active': usersActives[item?.code]}" class="bd-placeholder-img flex-shrink-0 me-2 base-img " [src]=""> -->
                                </div>

                                <div *ngIf="!item?.params?.selfie">

                                    <div [ngClass]="{'active': usersActives[item?.params?.code]}" style=" background-image: url(../assets/public/images/avatar.png); " class=" flex-shrink-0 me-2 base-img ">
                                    </div>

                                </div>



                                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <span class="text-dark fw-bold text-capitalize">{{item?.params?.name}} {{item?.params?.lastName}}</span>

                                        </div>
                                        <span class="text-secondary small">
                                            
                                            {{item?.params?.date | date: 'dd/MM/yyyy'}}<br class="d-md-none ">
                                             {{item?.params?.date | date: 'h:mm:ss a'}}


                                            <a class="ps-2 text-primary" [routerLink]="['/console/person/profile/',item?.params?.code]">
                                                Ir
                                            </a>

                                        </span>


                                    </div>
                                    <div class="mt-1 mb-2">
                                        <span class="d-block text-dark "> {{item?.params?.code_identity}}{{item?.params?.identity}}</span>

                                    </div>


                                    <div class="mt-1 mb-2 small">
                                        <span class=" text-dark "> {{item?.params?.phone}} / {{item?.params?.email}}</span>


                                    </div>



                                    <span class="text-secondary small text-capitalize " *ngIf="item?.person?.ejecutive"><span class="fw-bold">ejecutivo: </span> {{item?.person?.ejecutive?.name}} {{item?.person?.ejecutive?.lastName}}</span>

                                    <div>

                                        <span class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="item?.params?.person_biometric_status">Biometria : {{item?.params?.person_biometric_status}} </span>
                                        <span class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="item?.params?.onboarding_status">Onbording : {{item?.params?.onboarding_status}} / {{item?.params?.onboarding_sections_complete}}  </span>

                                        <span class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="item?.params?.approve_status">Estatus : {{item?.params?.approve_status}} </span>
                                        <span class="badge rounded-pill bg-warning me-2 mt-2 text-dark" *ngIf="item?.params?.review">En revisión </span>


                                       
                                    </div>

                                </div>
                            </div>
                            <ng-container *ngIf="!loading && rows.length < 1">
                                <div class="mt-2">
                                    <div class=" text-secondary mt-4">No se encontraron registros</div>
                                </div>
                            </ng-container>


                            <div *ngIf="!loading " class="mt-4 text-center">

                                <button type="button " class="btn btn-primary primary  " (click)="nextRows()">Siguientes + </button>

                            </div>


                            <ng-container *ngIf="loading ">
                                <div class="w-100 text-center mt-2">
                                    <img src="./assets/public/images/loading.gif  " alt=" ">
                                </div>
                            </ng-container>
                        </ng-container>

                    </div>

                    <!-- 
                <small class="d-block text-end mt-3">
                              <a href="#">All suggestions</a>
                            </small> -->
                </div>




            </div>
        </div>
    </div>
</div>