<div>

    <div class="pb-3 pt-4">
        <div *ngIf="biometria?.type == 'SELFIE'">
            <div class=" h5 fw-bold text-center">
                Tómale una foto al cliente
                <span class="fas fa-arrow-left text-secondary float-start ms-3 mt-1" (click)="close()"></span>
            </div>
            <div class="mt-3 text-md-center">
                Coloca el rostro del cliente en el círculo, y tome la foto o seleccione un archivo.
            </div>
        </div>

        <div *ngIf="biometria?.type == 'PHOTO_IDENTITY'">
            <div class=" h5 fw-bold text-center">
                Tómale una foto a tu Documento de Identidad (Cédula)
                <span class="fas fa-arrow-left text-secondary float-start ms-3 mt-1" (click)="close()"></span>
            </div>
            <div class="mt-3 text-md-center">
                Coloca el Documento de Identidad (Cédula) del cliente en la cámara, y tómale una foto o seleccione un archivo.
            </div>
        </div>

    </div>

    <div>
            <div>
                <div class="row">
                    <div class="col-12 col-md-4 offset-md-4">
                        <div class="d-grid gap-2 mt-3 text-center">

                            <mbsc-input [options]="mobileSettingsFile" (change)="onFileSelected($event)" type="file" placeholder="Seleccione archivo..." *ngIf="showUpload">Adjunte el soporte</mbsc-input>
                            <div *ngIf="fileName">
                                <div class="d-flex justify-content-between align-items-center mt-3">
                                    <div>{{fileName}}</div>
                                    <div class="text-primary text-decoration-underline" (click)="redirectIMG(fileUrl)">Ver</div>
                                </div>
                                
                                <div class="border-bottom pt-4 mb-3"></div>
                            </div>
                            <button *ngIf="fileSelected" class="btn btn-outline-primary me-2 btn-blo mt-2" (click)="submitFile()">Continuar</button>
                            
                            <button *ngIf="!webcamImage && !fileSelected && showCamera" class="btn btn-outline-primary me-2 btn-blo mt-2" (click)="changeTake()">Tomar foto</button>
                            <a *ngIf="webcamImage && !loading" class="text-secondary mt-2" (click)="webcamImage = undefined">Reset</a>
                            <button [disabled]="loading" (click)="submit()" *ngIf="webcamImage " class="btn btn-primary me-2 btn-blo mt-2">
                                <span *ngIf="!loading">Continuar</span>
                                <span *ngIf="loading">Guardando...</span>

                                </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    <div class="mt-5" *ngIf="showCamera">
        <div class="b-bg">
            <div class=" bio-circle-person " *ngIf="!webcamImage && biometria?.type == 'SELFIE'"></div>

            <img *ngIf="webcamImage" style="width: 100%; border-radius: 5px; " [src]="webcamImage.imageAsDataUrl" />

        </div>

        <app-webcam (getPicture)="handleImage($event)">
        </app-webcam>

    </div>


</div>