import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-client-reconsideration',
  templateUrl: './client-reconsideration.component.html',
  styleUrls: ['./client-reconsideration.component.css'],
})
export class ClientReconsiderationComponent implements OnInit {
  clientsReconsidered: any[] = [];
  clientsReconsideredNoResponse: any[] = [];
  personReconsidered: any;
  personReconsideredHistory: any[] = [];
  personIdentity: any;
  clientFiles: any[] = [];

  clientData: any = [];
  commentText: any;

  activeTab: string = 'review';
  
  personCode: any;

  loading: boolean = false;
  loadingModal: boolean = false;
  loadingSubmit: boolean = false;

  constructor(
    private _user: UserService,
    private offcanvasService: NgbOffcanvas,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const status = 'IN_EXECUTIVE';
    this.getAllClientsReconsidered(status);
  }

  modal = async (content: TemplateRef<any>, code: any, data: any = []) => {
    this.loadingModal = true;
    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,
    });
    this.clientData = data;
    this.personCode = this.clientData[2];
    console.log('this is the client data:', this.clientData);
    await this.getPersonReconsideredHistory(code);
    this.loadingModal = false;
  };

  modalMsg = async (content: TemplateRef<any>) => {
    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,
    });
    this.loadingModal = false;
  };

  autoExpand(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset height to auto to shrink if content is deleted
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
  }

  closeModal = async () => {
    this.offcanvasService.dismiss();
  };

  selectTab(tab: string) {
    this.activeTab = tab; // Switch the active tab
    this.getAllClientsReconsidered(this.activeTab);
  }

  redirectIMG(url: String) {
    const link = `${url}`;
    window.open(link, '_blank');
  }

  // solo trae los de estatus IN_EXECUTIVE
  async getAllClientsReconsidered(status: any) {
    this.loading = true;
    try {
      if (this.activeTab == 'review') {
        const resp = await this._user.getClientsReconsider(
          (status = 'IN_EXECUTIVE')
        );
        console.log('Full API Response:', resp);
        this.clientsReconsidered = resp;
        this.clientsReconsidered.forEach((item: any) => {
          this.clientFiles = item?.files;
        });
      } else {
        const resp = await this._user.getClientsReconsider(
          (status = 'IN_CLIENT')
        );
        this.clientsReconsideredNoResponse = resp;
        this.clientsReconsideredNoResponse.forEach((item: any) => {
          this.clientFiles = item?.files;
        });
      }

      this.loading = false;
      console.log('Updated clientsReconsidered:', this.clientsReconsidered);
    } catch (error) {
      console.error('Error fetching reconsidered clients:', error);
    }
  }

  async getPersonReconsidered(code_person: any) {
    debugger;
    try {
      const resp = await this._user.getPersonReconsider(code_person);
      this.personReconsidered = resp;
      console.log(resp);
    } catch (error) {
      console.error(error);
    }
  }

  async getPersonReconsideredHistory(code_person: any) {
    debugger;
    try {
      const resp = await this._user.getPersonReconsiderHistory(code_person);
      this.personReconsideredHistory = resp;
      console.log('This is the response:', resp);
    } catch (error) {
      console.error(error);
    }
  }

  async answerPersonReconsider(person_code: any, response: any, comments: any) {
    debugger;
    const body = {
      person_code,
      response,
      comments,
    };

    console.log(body);

    try {
      await this._user.answerPersonReconsider(body);
      alertifyjs.success("Enviado")
      this.closeModal();
      this.getPersonReconsideredHistory(person_code)
      this.getAllClientsReconsidered(this.activeTab);
    } catch (error) {
      console.error(error);
      alertifyjs.error('No se pudo enviar el mensaje')
    }
  }
}
