
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-contacts-approvated',
  templateUrl: './contacts-approvated.component.html',
  styleUrls: ['./contacts-approvated.component.css']
})
export class ContactsApprovatedComponent implements OnInit,OnDestroy {
  form:any;
  people:any = [];
  loading:any;
  usersActives:any = {};
  socketServiceSub:any;
  submit_disabled:any;
  active_filter:any;
  people_total:any = 0;
   reload_loading:any;
   rows_number = 0;
   rows_size = 10;
   reload_loading_pluss =true;
   rows:any = [];
   rows_temp:any = [];

   approveStatus:any  = 'ALL';
   biometricStatus:any  = 'ALL';
   onboardingStatus:any  = 'ALL';

   usQuoteClien:any = false;
   reloadStadisticLoading:any;




    supUse = false;

    meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  
   @Output() changeView = new EventEmitter<any>();

    page = 1; // Current page number
    limit = 50; // Number of documents per page

   search_form: any = '';
   config_line:any;
   sessionUser:any;


   constructor(
    private offcanvasService: NgbOffcanvas,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user:UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    public _auth : AuthService


  ) { }
  ngOnDestroy(): void {

    this.socketServiceSub.unsubscribe();
    
  }

  ngOnInit(): void {
   

    this._auth.auth$.subscribe((rep: any) => {

      this.sessionUser = rep? this._auth.sessionUser: null; 

      //nathalyaespisona@totalmundo.com
 });

    this.getPeople();

    this.socketServiceSub = this.socketService.usersActives.subscribe((resp: any) => {
      // this.usersActives = resp.filter((item:any) => item.uid != this.uid);
  
      this.usersActives = {};
 
       resp?.map((item:any)=>{
 
       if(item?.role ==  "CLIENT_ROLE"){
 
         this.usersActives[item?.person_code] = true;
 
       };
 
       return item;
 
      });


     });

    // this.setChartLine();    

  }

  openBottom(content: TemplateRef<any>,data:any= {}) {

    this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

  }

  closeAddClient(){
    this.getPeople();
    this.offcanvasService.dismiss("");
    
  }


  getPeople = async (filter_d:any = false) =>  {
    this.loading = true;

    this.rows = filter_d? [] :  this.rows;
    this.people_total = filter_d? 0 :  this.people_total;

    let filter:any = {

      page:this.page,
      limit: this.limit
 
     }

     if(filter_d){
      filter.page = 1; 
      filter.limit = 50; 
    }
  

    if(this.search_form?.trim() != ''){

  

      let code_ejecutive =  {f_all:this.search_form?.trim()}

        filter = {...filter,...code_ejecutive}

      }

    if(this._auth.IsPermitid(['EJECUTIVE'])){

   //  let code_ejecutive =  {code_ejecutive:this.sessionUser?.uid}

     // filter = {...filter,...code_ejecutive}

    }

      if(this.approveStatus != 'ALL'){

     let temp =  {approveStatus:this.approveStatus}

      filter = {...filter,...temp}
        
      }


      if(this.biometricStatus != 'ALL'){

        let temp =  {biometricStatus:this.biometricStatus}
   
         filter = {...filter,...temp}
           
         }


      if(this.onboardingStatus != 'ALL'){

        let temp =  {onboardingStatus:this.onboardingStatus}
   
         filter = {...filter,...temp}
           
         }
   


      if(this.usQuoteClien){

        let temp =  {reviewStatus:true}
   
         filter = {...filter,...temp}
           
         }
   

      

         debugger
    
    const resp =  await this._user.getPeople_status(filter);
    let result  = resp?.result?.users;
    this.people_total = resp?.result?.total;

    debugger
    let people:any = this.rows;
    let rows =  people.concat(result);

   // this.nextRows();

   this.rows = rows;


    this.loading = false;

  }


  nextRows = () =>{
/*
    this.reload_loading_pluss =true;

this.rows_number = this.rows_number + this.rows_size;

if(this.rows_number > this.people?.lenght){
 this.rows_number = this.people?.lenght;
}

let rows = this.people?.slice(0, this.rows_number);
this.rows = rows;

this.reload_loading_pluss = false;
  */


this.page = this.page + 1 ; 
this.getPeople();


  }

  
  getIdentity(item:any){

    if(!(item?.identitys?.length > 0))
      return "";

      return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
onScrollDown() {
  if(!this.active_filter){

   // this.nextRows();

   }

  console.log("scrolled down!!");
}

onScrollUp() {
  
  console.log("scrolled up!!");
}


  onScroll() {

    console.log("scrolled!!");
  }

  search_form_method() {

if(this.search_form?.trim() != ''){

  this.active_filter = true;

      this.rows_temp = this.rows_temp?.length > 0? this.rows_temp : this.rows;

      let datas:any = [];
      
       datas =  this.people?.filter((element:any) =>{

        let name =  element?.person?.name;
        let lastName =  element?.person?.lastName;
        let ename =  element?.person?.ejecutive?.name;
        let elastName =  element?.person?.ejecutive?.lastName;
        let identity = this.getIdentity(element)

      
        let resp = 
        identity.toLowerCase().includes(this.search_form) ||
         name.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
         lastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
        //  ename.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
        //  elastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
         

        return resp;
      });
      
      this.rows= datas;


      }else{
             

            this.rows = this.rows_temp?.length > 0? this.rows_temp : this.rows;
            this.active_filter = false;

      }

   }


setChartLine(){


  let dataList:any = [];
  
  let colors = [
          'orange',
          'blue',
          'grey',
          'green',
          'purple',
  ];


    let colorsBorder = [
      'purple',
      'yellow',
      'grey',
      'green',
      'purple',
  ];
    

  


      
  
  
    let datasets =  [
      {
        label: 'Dataset 1',
        data: 20,
        borderColor: colors[0],
        backgroundColor: colors[2],
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Dataset 2',
        data: 30,
        borderColor: colors[3],
        backgroundColor: colors[4],
        stack: 'bar'
      }
    ]
    
  
  
   const labels =["Enero", "Febrero"];



   this.config_line = {
  
      type: 'line', //this denotes tha type of chart
      data: {// values on X-Axis
        labels: labels, 
         datasets: datasets
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Chart.js Stacked Line/Bar Chart'
          }
        },
        scales: {
          y: {
            stacked: true
          }
        }
      },
      
    };
    
  
  }



  toogleStatusClient = async (st = this.usQuoteClien) => {
    let status = st ? true : false;

    this.usQuoteClien = status

    this.getPeople(true);

  };


}
