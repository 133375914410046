<div class="container">
    <div class="mt-md-3 p-3">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 p-0">
                <h5 class="mb-1 text-secondary mb-4 text-center">
                    <i class="fas fa-comment-dots"></i>
                    Reconsideración
                </h5>

                <div class="col-12 align-items-start d-flex">
                    <div class="mt-4 p-2 me-3 rounded shadow-sm" 
                            [ngClass]="{'bg-body text-secondary': activeTab !== 'review', 'bg-color text-light': activeTab === 'review'}" 
                            (click)="selectTab('review')"
                            style="cursor: pointer;">
                        <span class="mx-2">Por revisar</span>
                        </div>

                        <!-- <div class="mt-4 p-3 me-3 bg-body rounded shadow-sm">
                        <span>Por revisar</span>
                        </div> -->

                        <div class="mt-4 p-2 rounded shadow-sm" 
                                [ngClass]="{'bg-body text-secondary': activeTab !== 'noResponse', 'bg-color text-light': activeTab === 'noResponse'}"
                                (click)="selectTab('noResponse')"
                                style="cursor: pointer;">
                            <span class="mx-2">Sin respuesta</span>
                        </div>
                </div>

                <!-- <div *ngIf="!clientsReconsidered || clientsReconsidered.length === 0" class="mt-4">
                    <span>No se encontraron registros.</span>
                </div> -->

                <div *ngIf="loading" class="">
                    <div class="spinner-border text-primary mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                        </div>
                </div>

                <div *ngFor="let item of clientsReconsidered">
                <div class="col-12 mt-4 p-2 bg-body rounded shadow-sm" *ngIf="activeTab === 'review' && !loading">
                    <div class="p-2 d-flex justify-content-between align-items-center w-100 text-dark fw-bold text-capitalize">
                         {{item?.person?.name}} {{item?.person?.lastName}}
                        <i class="float-end fas fa-arrow-right text-warning" (click)="modal(clientReconsiderationInfo, item?.person.code, [item?.person?.name, item?.person?.lastName, item?.person?.code])"></i>
                    </div>

                    <div class="col-12 border-bottom mt-2 mb-0"></div>

                    <div class="p-2 mt-2 text-start w-100">
                        <span class="text-capitalize">{{item?.person?.name}} {{item?.person?.lastName}}</span><span class="ms-3 text-secondary">{{item?.update_date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2 mb-3 text-break w-50">{{item?.client_comments}}</div>
                        <div *ngFor="let file of item.files">
                            <div class="p-3 mt-2 bg-body rounded shadow-sm border col-6 d-flex justify-content-between">
                                <div>{{file?.name}}</div>
                                <i class="mt-1 fas fa-eye text-primary" (click)="redirectIMG(file?.url)"></i>
                            </div>
                            <!-- <img [src]="sanitize(file?.url)"> -->
                        </div>
                    </div>

                    <div class="mt-2 p-2 text-end w-100">
                        <span class="text-capitalize">{{item?.executive?.name}} {{item?.executive?.lastName}}</span><span class="ms-3 text-secondary">{{item?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2 text-break">{{item?.comments}}</div>
                    </div>
                    </div>

                </div>

                <!-- <div *ngIf="!clientsReconsideredNoResponse || clientsReconsideredNoResponse.length === 0 || !loading" class="mt-4">
                    <span>No se encontraron registros.</span>
                </div> -->

                <div *ngFor="let item of clientsReconsideredNoResponse">
                <div class="col-12 mt-4 p-2 bg-body rounded shadow-sm" *ngIf="activeTab === 'noResponse' && !loading">
                    <div class="p-2 d-flex justify-content-between align-items-center w-100 text-dark fw-bold text-capitalize">
                         {{item?.person?.name}} {{item?.person?.lastName}}
                        <i class="float-end fas fa-arrow-right text-warning" (click)="modal(clientReconsiderationInfo, item?.person.code, [item?.person?.name, item?.person?.lastName])"></i>
                    </div>

                    <div class="col-12 border-bottom mt-2 mb-0"></div>

                    <div class="p-2 mt-2 text-start w-100" *ngIf="item?.client_comments">
                        <span class="text-capitalize">{{item?.person?.name}} {{item?.person?.lastName}}</span><span class="ms-3 text-secondary">{{item?.update_date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2 mb-3 text-break w-50">{{item?.client_comments}}</div>
                        <div *ngFor="let file of item.files">
                            <div class="p-3 mt-2 bg-body rounded shadow-sm border col-6 d-flex justify-content-between">
                                <div>{{file?.name}}</div>
                                <i class="mt-1 fas fa-eye text-primary"></i>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2 p-2 text-end w-100">
                        <span class="text-capitalize">{{item?.executive?.name}} {{item?.executive?.lastName}}</span><span class="ms-3 text-secondary">{{item?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2">{{item?.comments}}</div>
                    </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #clientReconsiderationInfo>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 mt-4">
                <div class="d-flex justify-content-between p-2 text-muted">
                    <h4>Historial</h4>
                    <i class="fas fa-times text-secondary mt-2" style="font-size: 18px;" (click)="closeModal()"></i>
                </div>

                <div *ngIf="loadingModal">
                    <div class="spinner-border text-primary mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                        </div>
                </div>

                <div *ngIf="!loadingModal" class="scrollable-content">

                    <div class="p-2 text-dark h5 text-capitalize w-100">
                         <span>{{clientData[0]}} {{clientData[1]}}</span>
                    </div>

                    <div class="col-12 border-bottom mt-2 mb-0"></div>

                    <div *ngFor="let item of personReconsideredHistory">
                    <div class="p-2 mt-3 text-start w-100">
                        <span class="text-capitalize mt-2" *ngIf="item?.update_date">{{clientData[0]}} {{clientData[1]}}</span><span class="ms-3 text-secondary">{{item?.update_date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2 mb-3 text-break w-50">{{item?.client_comments}}</div>
                        <div *ngFor="let file of item.files">
                            <div class="p-3 mt-2 bg-body rounded shadow-sm border col-6 d-flex justify-content-between">
                                <div>{{file?.name}}</div>
                                <i class="mt-1 fas fa-eye text-primary" (click)="redirectIMG(file?.url)"></i>
                            </div>
                            <!-- <img [src]="sanitize(file?.url)"> -->
                        </div>
                    </div>

                    <div class="p-2 text-end w-100 mb-3">
                        <span class="text-capitalize">{{item?.executive?.name}} {{item?.executive?.lastName}}</span><span class="ms-3 text-secondary">{{item?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                        <div class="mt-2 text-break">{{item?.comments || item?.comments_response}}</div>
                    </div>

                </div>

                <div class="col-12 border-bottom mt-2 mb-0"></div>

                <div class="col-12 text-end mt-4">
                    <span class="text-primary" (click)="modalMsg(message)">
                        <i class="fas fa-comment text-primary me-1"></i>
                        Responder
                    </span>
                    <button class="btn btn-danger me-3 ms-3">
                        Rechazar
                    </button>
                    <button class="btn btn-success">
                        Aprobar
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #message>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 mt-4">
                <div class="d-flex justify-content-between p-2 text-muted">
                    <h4>Responder</h4>
                    <i class="fas fa-times text-secondary mt-2" style="font-size: 18px;" (click)="closeModal()"></i>
                </div>

                <div class="scrollable-content">

                    <div class="p-2">
                         <textarea
                            class="form-control"
                            (input)="autoExpand($event)"
                            [(ngModel)]="commentText"
                            placeholder="Escriba un mensaje"
                            >
                        </textarea>
                    </div>


                <div class="col-12 text-end mt-2 p-2">
                    <button class="btn btn-primary" (click)="answerPersonReconsider(personCode, 'RETURNED', commentText)">
                        Enviar
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>

</ng-template>

<!-- <pre>{{ personReconsideredHistory | json}}</pre> -->


